<template>
  <v-app>
    <v-container
      id="login-container"
      fluid
      tag="section"
      class="fill-height"
    >
      <v-row
        justify="center"
      >
        <v-col
          cols="12"
          md="4"
        >
          <v-card
            elevation="24"
            class="px-4 py-7"
          >
            <v-form
              @submit.prevent="connect"
            >
              <v-row
                justify="center"
              >
                <v-col
                  cols="10"
                >
                  <v-text-field
                    outlined
                    label="Identifiant"
                    class="purple-input"
                    type="email"
                    v-model="username"
                  />
                </v-col>
                <v-col
                  cols="10"
                >
                  <v-text-field
                    outlined
                    v-model="password"
                    label="Mot de passe"
                    :type="showpass ? 'text' : 'password'"
                    :error="error"
                    @input="error = false"
                  >
                    <template v-slot:append>
                      <v-icon
                        v-if="!showpass"
                        class="cursor-pointer"
                        @click="showpass = true"
                      >
                          mdi-eye
                      </v-icon>
                      <v-icon
                        v-if="showpass"
                        class="cursor-pointer"
                        @click="showpass = false"
                      >
                          mdi-eye-off
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row
                justify="center"
              >
                <v-col
                  cols="10"
                >
                  <v-checkbox
                    v-model="staylogged"
                    label="Rester connecté"
                  />
                </v-col>
                <v-col
                  cols="10"
                >
                  <v-btn
                    type="submit"
                    color="primary"
                    block
                    large
                    :disabled="connectDisabled"
                  >
                    Se connecter
                  </v-btn>
                </v-col>
              </v-row>
              <v-row
                justify="center"
                class="justify-end"
              >
                <v-col
                  cols="10"
                  class="align-center"
                >
                  <a
                    id="mdp-oublie"
                    class="lien"
                    @click="$router.push({ name: 'ForgotPassword' })"
                  >Mot de passe oublié</a>
                  <span class="mx-3">•</span>
                  <a
                    id="creer-compte"
                    class="lien"
                    href="https://trainyflow.com/inscription"
                  >Pas encore de compte ?</a>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <dashboard-core-alert-snackbar />
  </v-app>
</template>

<script>

  import { mapMutations } from 'vuex';

  export default {
    name: 'Login',

    components: {
      DashboardCoreAlertSnackbar: () => import('../dashboard/components/core/AlertSnackbar'),
    },

    data() {
      return {
        username: '',
        password: '',
        staylogged: false,
        showpass: false,
        error: false,
        connectDisabled: false,
      }
    },

    created() {
      localStorage.removeItem('mcdm-user');
      localStorage.removeItem('mcdm-token');
      sessionStorage.removeItem('mcdm-token');
      delete this.axios.defaults.headers.common.Authorization;

      setTimeout(() => {
        if (this.$route.params.snack) {
          this.setSnack(this.$route.params.snack);
        }
      }, 500)
    },

    methods: {
      connect() {
        this.connectDisabled = true;
        const credentials = { username: this.username, password: this.password };
        delete this.axios.defaults.headers.common.Authorization;
        this.axios.post('/auth', credentials, { withCredentials: true })
          .then((res) => {
            if (this.staylogged) {
              localStorage.setItem('mcdm-token', res.data.accessToken);
            } else {
              sessionStorage.setItem('mcdm-token', res.data.accessToken);
            }

            return this.axios.get('/auth/validate', { headers: { Authorization: 'Bearer ' + res.data.accessToken } });
          })
          .then((res) => {
            if (['SUPER', 'ADMIN'].includes(res.data.role)) {
              if (this.$route.params.path) {
                this.$router.push({ path: this.$route.params.path });
              } else {
                this.$router.push({ name: 'Home' });
              }
              localStorage.setItem('mcdm-user', JSON.stringify(res.data));
            } else {
              this.connectDisabled = false;
              this.password = '';
              this.setSnack({ text: 'Vous n\'êtes pas autorisé à accéder à cet espace', type: 'warn' });
            }
            return res;
          })
          .catch((e) => {
            this.connectDisabled = false;
            this.password = '';
            if (e.response.status === 403) {
              this.setSnack({ text: 'Mauvais login / mot de passe', type: 'warn' });
              this.error = true;
            } else {
              this.setSnack({ text: 'Erreur de connexion', type: 'warn' });
            }
          });
      },

      ...mapMutations({
        setSnack: 'SET_SNACK',
      }),
    },
  }
</script>

<style scoped>
  #login-container {
    min-height: 100vh;
    background-image: url('../../assets/wallpaper-login.jpg');
    background-size: cover;
    background-position: center;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  #login-container >>> .align-center {
    text-align: center;
  }

  #login-container >>> .lien {
    text-decoration: underline;
    font-size: 0.9em;
  }
</style>
